import React from "react"
import Layout from "../components/Layout"

export default function Home({ data }) {
  return (
    <Layout>
      <main>
        <section className="page-callout">
          <h1>Iain Pritchard</h1>
        </section>

        <section>
          <div className="description">
            <p>Software Engineering Manager based in Glasgow, Scotland.</p>
          </div>
          <section className="link-to-more">
            <a href="/profile">Find out more.</a>
          </section>
        </section>
      </main>
    </Layout>
  )
}
